import React, { useState, useEffect, useRef, useCallback } from "react"
import { FaQuoteRight } from "react-icons/fa"
import { FcServices } from "react-icons/fc"
import { fabric } from "fabric"

import SEO from "../../components/SEO/SEO"
import Layout from "../../components/Layout"
import SeeAlso from "../../components/SeeAlso/SeeAlso"
import NavBar from "../../components/NavBar/NavBar"
import { isSSR } from "../../components/Script/script"

import {
  Title,
  SubTitle,
  LoadingAnimation,
  Button,
  BoxShadowWindow,
  Dropdown,
  Icon,
  ArrowDown,
} from "../../StyleComponents/styles"
import { FlexDiv } from "../../StyleComponents/pagesStyle"

import TweetButton from "./components/tweetButtonShare"

const DropDownQuaotList = React.lazy(() =>
  import("./components/dropDownQuotList")
)
const Pagination = React.lazy(() =>
  import("../../components/Pagination/Pagination")
)
const SettingsBox = React.lazy(() => import("./components/Settings"))
const Posts = React.lazy(() => import("./components/Posts"))

const seeAlsoArray = [
  "/font-generator",
  "/emoticons-copy-and-paste",
  "/text-emoticons-face-generator",
  "/emoji-copy-and-paste",
  "/short-joke-generator",
]

function QuoteGenerator(props) {
  const {
    currentPage,
    totalPages,
    pageItems,
    allPages,
    name,
    alllink,
  } = props.pageContext
  let pathName =
    name === "" ? "quote-generator" : `${name.toLowerCase()}-quote-generator`

  const isFirst = currentPage === 1
  const isLast = currentPage === totalPages
  const prevPage =
    currentPage - 1 === 1
      ? `/${pathName}/`
      : `/${pathName}/${(currentPage - 1).toString()}/`

  const nextPage = `/${pathName}/${(currentPage + 1).toString()}/`
  let currentQuote = pageItems
  let quoteList = allPages

  let [quoteMain, setquoteMain] = useState("")
  let [quoteAuthor, setquoteAuthor] = useState("")

  let [generateImageOnly, setGenerateImageOnly] = useState(0)

  let [buttonCopyText, setButtonCopyText] = useState("Copy Text")
  // localStorage.setItem("color", JSON.stringify(e.target.value))
  let [color, setcolor] = useState(() => {
    return typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("color")) || "#FFFFFF"
      : "#FFFFFF"
  })

  let [imageGenre, setimageGenre] = useState(() => {
    return typeof window !== "undefined"
      ? localStorage.getItem("imageType") || ""
      : ""
  })
  let [fontStyle, setfontStyle] = useState(() => {
    return typeof window !== "undefined"
      ? localStorage.getItem("fontStyle") || "bold"
      : "bold"
  })
  let [fontValue, setfontValue] = useState(() => {
    return typeof window !== "undefined"
      ? localStorage.getItem("textFont") || "Quicksand"
      : "Quicksand"
  })
  let [textAlignValue, settextAlignValue] = useState(() => {
    return typeof window !== "undefined"
      ? localStorage.getItem("textAlign") || "center"
      : "center"
  })

  let [settings, setsettings] = useState(false)
  let [fontSadow, setfontSadow] = useState(1)

  const canvasFabricRef = useRef(null)
  const canvas = useRef(null)
  let width = useRef(640)
  let height = useRef(450)
  let scaleFactor = useRef(null)

  function changeSettings() {
    if (settings === false) {
      setsettings(true)
    } else {
      setsettings(false)
    }
  }

  useEffect(() => {
    generateQuoteList()
    if (typeof window !== "undefined") {
      if (window.screen.width <= 450) {
        scaleFactor.current = 0.5
      } else if (window.screen.width > 450 && window.screen.width < 800) {
        scaleFactor.current = 0.8
      } else if (window.screen.width > 1200) {
        scaleFactor.current = 1.2
      } else {
        scaleFactor.current = 1
      }
      canvas.current = new fabric.Canvas(canvasFabricRef.current)
      width.current = width.current * scaleFactor.current
      height.current = height.current * scaleFactor.current
      canvas.current.setWidth(width.current)
      canvas.current.setHeight(height.current)
      canvas.current.calcOffset()

      if (scaleFactor.current !== 1) {
        for (var i = 0; i < canvas.current._objects.length; i++) {
          canvas.current._objects[i].scale(scaleFactor.current)
          canvas.current._objects[i].setLeft(
            canvas._objects[i].left * scaleFactor.current
          )
          canvas.current._objects[i].setTop(
            canvas._objects[i].top * scaleFactor.current
          )
        }
        canvas.current.renderAll()
      }
    }
  }, [])

  useEffect(() => {
    canvas.current.setBackgroundImage(
      null,
      canvas.current.renderAll.bind(canvas.current)
    )
    fetch(`https://picsum.photos/1200/720${imageGenre}`)
      .then(response => {
        let fetchedImage = response.url
        const image = new Image()
        image.src = fetchedImage
        image.crossOrigin = "anonymous"
        image.onload = function () {
          const photo = new fabric.Image(image)

          canvas.current.setBackgroundImage(
            photo,
            canvas.current.renderAll.bind(canvas.current),
            {
              scaleX: canvas.current.width / photo.width,
              scaleY: canvas.current.height / photo.height,
            }
          )
        }
      })
      .catch(error => console.log(error))
  }, [generateImageOnly, imageGenre])

  useEffect(() => {
    let objects = canvas.current.getObjects("textbox")
    canvas.current.remove(...objects)
    let text = new fabric.Textbox(`" ${quoteMain} "`, {
      left: 25,
      top: canvas.current.height / 6,
      width: canvas.current.width - 50,
      fontSize: 30 * scaleFactor.current,
      fontStyle: fontStyle,
      strokeWidth: 1,
      stroke: "black",
      fontFamily: fontValue,
      fill: color,
      shadow: "rgba(0,0,0,0.90) 3px 3px 3px",
      textAlign: textAlignValue,
      objectCaching: false,
    })

    let authorText = new fabric.Textbox(`~ ${quoteAuthor}`, {
      left: canvas.current.width / 2,
      top: canvas.current.height / 1.2,
      fontSize: 23 * scaleFactor.current,
      fontStyle: fontStyle,
      width: canvas.current.width / 2,
      strokeWidth: 1,
      stroke: "black",
      fontFamily: fontValue,
      fill: color,
      objectCaching: false,
    })
    canvas.current.add(text, authorText)
  }, [quoteMain])

  const textEditor = (e, set, attribute) => {
    set(e.target.value)
    canvas.current.getObjects().map(a => {
      return a.set(attribute, e.target.value)
    })
    canvas.current.requestRenderAll()
  }
  const changeColor = e => {
    setcolor(e.target.value)
    canvas.current.getObjects().map(a => {
      return a.set("fill", e.target.value)
    })
    canvas.current.requestRenderAll()
    if (typeof window !== "undefined") {
      localStorage.setItem("color", JSON.stringify(e.target.value))
    }
  }
  const changeFontStyle = e => {
    textEditor(e, setfontStyle, "fontStyle")
    if (typeof window !== "undefined") {
      localStorage.setItem("fontStyle", e.target.value)
    }
  }
  const randomImage = () => {
    setGenerateImageOnly(generateImageOnly + 1)
  }
  const changeFont = e => {
    textEditor(e, setfontValue, "fontFamily")
    if (typeof window !== "undefined") {
      localStorage.setItem("textFont", e.target.value)
    }
  }
  const changeTextAlign = e => {
    textEditor(e, settextAlignValue, "textAlign")
    if (typeof window !== "undefined") {
      localStorage.setItem("textAlign", e.target.value)
    }
  }
  const changefontSadow = e => {
    setfontSadow(e.target.value)
    canvas.current.getObjects().map(a => {
      return a.set(
        "shadow",
        `rgba(0,0,0,0.90) ${e.target.value}px ${e.target.value}px ${e.target.value}px`
      )
    })
    canvas.current.requestRenderAll()
  }
  const clearSahow = e => {
    setfontSadow(0)
    canvas.current.getObjects().map(a => {
      return a.set("shadow", "")
    })
    canvas.current.requestRenderAll()
  }

  const imageType = e => {
    setimageGenre(e.target.value)
    if (typeof window !== "undefined") {
      localStorage.setItem("imageType", e.target.value)
    }
  }
  function generateQuoteList() {
    let quoteRandomize = Math.floor(Math.random() * quoteList.length)
    setquoteMain(quoteList[quoteRandomize].quote)
    setquoteAuthor(quoteList[quoteRandomize].name)
  }
  const addQuoteToImage = useCallback((quote, author) => {
    setquoteMain(quote)
    setquoteAuthor(author)
  }, [])

  function generateQuote() {
    generateQuoteList()
  }

  function download_image() {
    const a = document.createElement("a")
    a.href = canvasFabricRef.current.toDataURL("image/png")
    a.download = "quote-image.png"
    a.click()
  }

  function copyTextQuote(e) {
    let elem = document.createElement("textarea")
    document.body.appendChild(elem)
    elem.value = `" ${quoteMain} " ~ ${quoteAuthor}`
    elem.select()
    let copied = document.execCommand("copy")
    document.body.removeChild(elem)

    if (copied) {
      setButtonCopyText("Copied")
      setTimeout(function () {
        setButtonCopyText("Copy Text")
      }, 1500)
    }
  }
  return (
    <Layout location={props.location}>
      <SEO
        title={`${name} Quote Generator`}
        description={`${name} Quote Generator, Simple Generate random ${name} quote, big list of ${name} quotes, share your ${name} quote by twitter, make a tweet ${name} quote every day.`}
        keywords={[
          `Quote, daily quote, generate ${name} quote, popular ${name} quote, big list of ${name} quote, random ${name} quote, share ${name} quote, tweet ${name} quote, many ${name} quote, make tweet ${name} quote, ${name} quote`,
        ]}
      />
      <NavBar
        listPages={React.useMemo(() => {
          return [
            { name: "Generator", link: "/generator/" },
            { name: "Quote Generator", link: "/quote-generator/" },
            { name: name, link: `/${pathName}/` },
          ]
        }, [name, pathName])}
      />
      <div className="container">
        <Title>
          <FaQuoteRight /> {name} Quote Generator
        </Title>
        <SubTitle>Generate Daily {name} Quote</SubTitle>
        <br />

        <div style={{ textAlign: "-webkit-center" }}>
          <canvas
            ref={canvasFabricRef}
            style={{
              border: "1px solid black",
            }}
          ></canvas>
        </div>
        <br />
        <FlexDiv
          displayDiv="flex"
          flexWrap="wrap"
          maxWidth="700px"
          margin="auto"
        >
          <Button
            className="m-2"
            borderColor="#1da1f2"
            color="#1da1f2"
            hoverColor="white"
            hoverBorderColor="#1da1f2"
            hoverBackgroundColor="#1da1f2"
            onClick={generateQuote}
          >
            Generate Quote
          </Button>
          <Button
            className="m-2"
            borderColor="#1da1f2"
            color="#1da1f2"
            hoverColor="white"
            hoverBorderColor="#1da1f2"
            hoverBackgroundColor="#1da1f2"
            onClick={randomImage}
          >
            Generate Image
          </Button>

          <Button className="m-2" onClick={download_image}>
            Download Image
          </Button>

          <Button className="m-2" onClick={copyTextQuote}>
            {buttonCopyText}
          </Button>

          <div className=" p-2 m-2">
            {quoteMain !== "" ? (
              <TweetButton tweet={quoteMain} author={quoteAuthor} />
            ) : (
              ""
            )}
          </div>
        </FlexDiv>
        <FlexDiv maxWidth="500px" margin="auto">
          <Button className="mb-1" onClick={changeSettings}>
            Settings <FcServices size={26} />
          </Button>

          {settings
            ? !isSSR && (
                <React.Suspense fallback={<LoadingAnimation />}>
                  <SettingsBox
                    color={color}
                    changeColor={changeColor}
                    imageType={imageType}
                    imageGenre={imageGenre}
                    changeFontStyle={changeFontStyle}
                    fontStyle={fontStyle}
                    changeFont={changeFont}
                    fontValue={fontValue}
                    changeTextAlign={changeTextAlign}
                    textAlignValue={textAlignValue}
                    fontSadow={fontSadow}
                    changefontSadow={changefontSadow}
                    clearSahow={clearSahow}
                  />
                </React.Suspense>
              )
            : ""}
        </FlexDiv>
        <br />
        <Dropdown className="mb-4">
          <Button aria-haspopup="true" aria-controls="dropdown-menu">
            Quote Category
            <Icon>
              <ArrowDown />
            </Icon>
          </Button>

          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <DropDownQuaotList list={alllink} />
            </React.Suspense>
          )}
          <br />
        </Dropdown>
        <BoxShadowWindow>
          <div
            style={{
              padding: "15px",
              height: "70vh",
            }}
          >
            {!isSSR && (
              <React.Suspense fallback={<LoadingAnimation />}>
                <Posts
                  quotes={currentQuote}
                  addQuoteToImage={addQuoteToImage}
                />
              </React.Suspense>
            )}
            <br />
          </div>
        </BoxShadowWindow>
        <br />
        {!isSSR && (
          <React.Suspense fallback={<LoadingAnimation />}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              isFirst={isFirst}
              isLast={isLast}
              prevPage={prevPage}
              nextPage={nextPage}
              pagesLink={pathName}
            />
          </React.Suspense>
        )}
        {/* <NavigatorQuote /> */}
        <br />
        <div>
          <h3>{name} Quote Generator</h3>
          <p>
            {name} Quote Generator, Simple Generate random {name} quote, big
            list of {name} quotes, share your {name} quote by twitter, make a
            tweet {name} quote every day.
          </p>
        </div>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default QuoteGenerator
