import React from "react"
import { FaTwitter } from "react-icons/fa"

function TweetButton({ tweet, author }) {
  let myUrl =
    typeof window !== "undefined"
      ? window.location.href
      : "https://www.unitpedia.com"
  return (
    <a
    data-text=""
    data-show-count="false"
      href={`https://twitter.com/intent/tweet?url=${myUrl}&text=“ ${tweet} ” ~${author}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      <FaTwitter size={24} />
    </a>
  )
}

export default React.memo(TweetButton)
